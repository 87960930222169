exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-contact-tsx": () => import("./../../../src/pages/components/Contact.tsx" /* webpackChunkName: "component---src-pages-components-contact-tsx" */),
  "component---src-pages-components-gallery-tsx": () => import("./../../../src/pages/components/Gallery.tsx" /* webpackChunkName: "component---src-pages-components-gallery-tsx" */),
  "component---src-pages-components-layout-1-tsx": () => import("./../../../src/pages/components/Layout1.tsx" /* webpackChunkName: "component---src-pages-components-layout-1-tsx" */),
  "component---src-pages-components-navbar-tsx": () => import("./../../../src/pages/components/Navbar.tsx" /* webpackChunkName: "component---src-pages-components-navbar-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/Header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

